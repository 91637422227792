import { Component } from '@angular/core';
import {DatePipe} from "@angular/common";
import {ChartFakeComponent} from "../../../components/public/chart-fake/chart-fake.component";
import { ButtonComponent } from "../../../components/shared/button.component";

@Component({
  selector: 'app-under-construction',
  standalone: true,
  imports: [
    DatePipe,
    ChartFakeComponent,
    ButtonComponent
],
  templateUrl: './under-construction.component.html',
  styleUrl: './under-construction.component.css'
})
export class UnderConstructionComponent {
  protected readonly year = new Date();
}
