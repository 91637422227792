import { Routes } from '@angular/router';
import {tokenGuard} from "./helpers/tokenGuard";
import { environment } from '../environments/environment.development';
import { UnderConstructionComponent } from './pages/public/under-construction/under-construction.component';

export const urlRoutes = {
  LOGIN: 'login',
  HOME: '',
  DETAIL: 'crisi',
  CHISIAMO: 'chi-siamo',
  METODOLOGIA: 'metodologia',
  CONTATTI: 'contatti',
  PRIVACYPOLICY : 'privacy-policy',
  COOKIEPOLICY : 'cookie-policy',
  ADMIN_FIND: 'admin/find',
  ADMIN_DETAIL: 'admin/crisi'
}

export const routes: Routes = [
  { path: urlRoutes.LOGIN, loadComponent: () => import('./pages/admin/login/login.component') },
  { path: urlRoutes.HOME, loadComponent: () => import('./pages/public/home/home.component') },
  { path: urlRoutes.CHISIAMO, loadComponent: () => import('./pages/public/chi-siamo/chi-siamo.component') },
  { path: urlRoutes.CONTATTI, loadComponent: () => import('./pages/public/contatti/contatti.component') },
  { path: urlRoutes.DETAIL + '/:name', loadComponent: () => import('./pages/public/detail/detail.component')},
  { path: urlRoutes.METODOLOGIA, loadComponent: () => import('./pages/public/metodologia/metodologia.component') },
  { path: urlRoutes.PRIVACYPOLICY, loadComponent: () => import('./pages/public/privacy-policy/privacy-policy.component') },
  { path: urlRoutes.COOKIEPOLICY, loadComponent: () => import('./pages/public/cookie-policy/cookie-policy.component') },
  { path: urlRoutes.ADMIN_FIND, loadComponent: () => import('./pages/admin/find/find.component'), canActivate: [tokenGuard]},
  { path: urlRoutes.ADMIN_DETAIL + '/:name', loadComponent: () => import('./pages/admin/detail/detail.component'), canActivate: [tokenGuard]},
  { path: 'admin', redirectTo: urlRoutes.ADMIN_FIND },
  { path: '**', pathMatch: 'full', loadComponent: () => import('./pages/public/page404/page404.component') },
];

export const underConstructionRoute: Routes = [
  { path: '**', pathMatch: 'full', component: UnderConstructionComponent }
];