<nav class="navbar navbar-expand-lg bg-dark text-white fixed-top">
    <div class="container py-3">
        <div class="col-10 col-lg">
            <h1 class="h4 fw-bold pointer d-flex align-items-center mb-0 text-lg-nowrap">
                <img src="assets/osservatorio_white.svg" alt="Osservatorio crisi" class="logo me-3" />
                Osservatorio crisi reputazionali
            </h1>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll">
            <span class="navbar-toggler-icon text-white"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                <li class="nav-item">
                    <div class="rounded border bg-light">
                        <a class="nav-link mx-2 mx-xl-4 text-white" href="https://www.reputationmanager.it"
                            target="_blank" title="ReputationManager" style="font-weight: normal;">
                            <img src="../../../../assets/rm_logo.png" alt="ReputationManager" style="height:30px">
                        </a>
                    </div>

                </li>
                <li class="nav-item d-block d-lg-none">
                    <a class="nav-link mx-2 mx-xl-4">Contatti</a>
                </li>
            </ul>
        </div>
    </div>
</nav>


    <div class="container container-page-public position-relative py-5">
        <div class="row align-items-center p-5">
            <div class="col-12 col-xl-8 px-5">
                <p class="h4 mt-4 fw-semibold" [style.line-height]="1.3"><strong>Osservatorio crisi in Manutenzione</strong></p>
                <p class="h4 mb-0 fw-semibold" [style.line-height]="1.3">Il sito è temporaneamente non disponibile.
                    <br />
                    Stiamo lavorando per ripristinarne l'operatività.</p>
            </div>
            <div class="d-none d-xl-block col-xl-4">
                <img src="../../../../assets/under-construction-ico.svg" alt="under_construction" class="img-fluid"
                    style="width: 250px;">
            </div>
        </div>
    </div>



<footer class="bg-dark text-white py-3">

            <div class="container-fluid bg-dark py-3 py-lg-5 p-lg-5">
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-12 col-lg-5">
                            <p class="h4 fw-bold d-flex align-items-center mb-0 text-white">
                                <img src="assets/osservatorio_white.svg" alt="Osservatorio crisi reputazionali"
                                    class="logo me-3" />
                                Osservatorio crisi reputazionali
                            </p>
                        </div>
                        <div class="col-12 col-xl d-none d-xl-block">
                        </div>
                        <div class="col-12 col-xl d-none d-xl-block">
                        </div>
                        <div class="col-12 col-xl-4 mt-4 mt-xl-0">
                            <p class="mb-0 mt-2 d-flex align-items-center">
                                <img src="assets/icon/phone_icon_white.svg" alt="number phone" height="15" />
                                <span class="text-white ms-2 d-none d-md-block">Tel:</span>
                                <a href="tel:+3902928501" class="text-white fw-normal ms-2">+39 02 928501</a>
                            </p>
                            <p class="mb-0 mt-2 d-md-flex align-items-center">
                                <img src="assets/icon/email_icon_white.svg" alt="email" height="15" />
                                <span class="text-white ms-2 d-none d-md-block">E-mail:</span>
                                <a href="mailto:osservatorio.crisi@reputationmanager.it"
                                    class="text-white fw-normal ms-2">osservatorio.crisi&#64;reputationmanager.it</a>
                            </p>
                            <p class="mb-0 mt-2 d-flex align-items-center">
                                <img src="assets/icon/location_icon_white.svg" alt="place" height="15" />
                                <span class="text-white ms-2">
                                    <a href="https://www.google.com/maps?ll=45.465823,9.191566&z=15&t=m&hl=it&gl=IT&mapclient=embed&cid=2367125191132781532"
                                        target="_blank" class="text-white" title="Sede RM Milano"
                                        style="font-weight: normal;">
                                        Milano</a>
                                    -<a href="https://www.google.com/maps?ll=41.89927,12.473764&z=16&t=m&hl=it&gl=IT&mapclient=embed&cid=2298260068702219127"
                                        target="_blank" class="text-white" title="Sede RM Roma"
                                        style="font-weight: normal;">
                                        Roma</a>
                                </span>
                            </p>
                        </div>
                    </div>
                    <hr class="mt-lg-5 mt-4" />
                    <div class="row">
                        <div class="col-12 px-lg-5 mt-2">
                            <div class="d-lg-flex align-items-center justify-content-between">
                                <div class="mb-0 text-white">&#169; {{year | date:'yyyy'}} <h2
                                        style="display:inline; font-size: inherit; font-weight: normal"><a
                                            href="https://www.reputationmanager.it/" target="_blank "
                                            title="Reputation Manager" class="text-white">Reputation Manager&reg;
                                        </a>S.p.A. Società Benefit </h2> | All rights
                                    reserved</div>
                                <p class="mb-0 text-white mt-4 mt-lg-0">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

</footer>