import {ChangeDetectionStrategy, Component, EventEmitter, input, OnInit, Output} from '@angular/core';
import * as Highcharts from "highcharts";

@Component({
  selector: 'p-chart-fake',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  templateUrl: './chart-fake.component.html',
  styleUrl: './chart-fake.component.css'
})
export class ChartFakeComponent implements OnInit{
  for404 = input<boolean>(false);
  responsive = input<boolean>(false);

  @Output() dimensionClicked = new EventEmitter<string>();
  ngOnInit() {
    let fakeCat = ["Brand", "Mercato", "Finanziaria", "Environment", "Sociale", "Governance", "Asset", "Politica", "Giudiziario", "Stakeholder", "Contesto"];
    let fakeData = [95, 30, 50, 40, 35, 60, 50, 80, 40, 50, 80];

    if(this.for404()){
      fakeCat = ["404", "404", "404", "404", "404", "404", "404", "404", "404", "404", "404"];
      fakeData = [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60];
    }
    this.create(fakeCat, fakeData);
  }

  private create(cat: string[], data: number[]){
    const width = this.responsive() ? document.getElementById('crisisChartFake')!.clientWidth : 400;
    const panel = this.responsive() ? width / 2.2 : 185;
    let chart = Highcharts.chart('crisisChartFake', {
      chart: {
        polar: true,
        type: 'area',
        width: this.for404() ? 500 : width,
        height: this.for404() ? 350 : 300,
      },
      title: {
        text: '',
      },
      pane: {
        size: this.for404() ? 250 : panel,
      },
      plotOptions: {
        series: {
          lineWidth: 5,
          cursor: 'pointer',
          marker: {
            radius: 5,
            lineWidth: 2,
            lineColor: "#FFFFFF"
          }
        }
      },
      xAxis: {
        categories: cat,
        gridLineColor: '#F4F6F8',
        gridLineWidth: 4,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          allowOverlap: true,
          useHTML: true,
          style: {
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.8rem'
          },
          formatter: function() {
              return `<span class="px-2 py-1 rounded-pill fw-semibold pointer">${ this.value}</span>`;
          }
        }
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        gridLineColor: '#F4F6F8',
        gridLineWidth: 4,
        lineWidth: 0,
        min: 0,
        max: 100,
        minorGridLineWidth: 0,
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
        tickInterval: 0
      },
      series: [{
        type: 'area',
        name: 'Area',
        data: data,
        fillOpacity: 0.2,
        color: `var(--main)`,
        point: {
          events: {
            click: function () {
              onPointClicked(this.category as string, true)
            },
          }
        }
      }],
      tooltip: {
        enabled: false,
        borderColor: 'black',
        x: "nome",
        formatter: function() {
          return this.x;
        }
      },
      exporting:{
        enabled: false
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      }
    } as Highcharts.Options);


    const onClicked = (label:any, dimensionClicked:EventEmitter<string>) => {
      const initialDimension = 'Brand';
      onPointClicked(initialDimension, false);

      label.onclick = function(){
        xAxis.labelGroup.div.childNodes.forEach((otherLabels : any) => {
          const otherSpan = otherLabels.children[0];
          otherSpan.style.background = `var(--light-gray)`;
          otherSpan.style.color = 'var(--black)';
        });
        const span = label.children[0];
        span.style.background = `var(--main)`;
        span.style.color = "white";
        dimensionClicked.emit(this.textContent);
      }
    }

    const onPointClicked = (dimension: string, fromPoint: boolean) => {
      xAxis.labelGroup.div.childNodes.forEach((label : any) => {
        const allSpan = label.children[0];
        if(allSpan.innerHTML === dimension){
          allSpan.style.background = `var(--main)`;
          allSpan.style.color = "white";
        }
        else{
          allSpan.style.background = `var(--light-gray)`;
          allSpan.style.color = 'var(--black)';
        }
      });

      if(fromPoint){
        this.dimensionClicked.emit(dimension)
      }
    }

    const xAxis =  chart.xAxis[0] as any;
    xAxis.labelGroup.div.childNodes.forEach((label : any) => onClicked(label, this.dimensionClicked));
  }
}
