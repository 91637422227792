import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {urlRoutes} from "../app.routes";

export const tokenGuard: CanActivateFn = ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const token = !!localStorage.getItem('TOKEN')
  if(!token){
    const router = inject(Router);
    router.navigateByUrl(urlRoutes.LOGIN);
  }
  return token;
}
